<template>
    <Modal :options="{close: true}" @close="$emit('close')">
        <div class="title">Observaciones {{ area_visual }}</div>
        <div class="body">
            <div v-for="observacion in observaciones" class="observacion">
                <div class="comentario">
                    {{ observacion.observaciones }}
                </div>
                <div class="fecha">
                    {{ observacion.usuario && observacion.usuario.name ? observacion.usuario.name : 'Sistema' }} - {{ observacion.created_at }}
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  methods: {
  },
    components: {
        Modal
    }
    ,props: {
        solicitud: {
            type: Object,
            default() {
                return {
                    observaciones: []
                }
            }
        }
        ,area: {
            type: String,
            default: 'callback'
        }
    }
    ,computed: {
        observaciones() {
            let observaciones = [];

            this.solicitud.observaciones.forEach(item => {
                if (item.modulo == this.area)
                    observaciones.push(item);
            })

            return observaciones;
        }
        ,area_visual() {
            switch(this.area) {
                case 'callback':
                    return 'CallBack';
                case 'opinion':
                    return 'Opinión de valor';
                case 'citas':
                    return 'Preautorización';
                case 'entrevistas':
                    return 'Documentación';
                case 'comite':
                    return 'Autorización';
                case 'ejercido':
                    return 'Ejercido';
                case 'desarrollo':
                    return 'Desarrollo Empresarial';
            }
        }
    }
}
</script>

<style lang="scss">
    .observacion {
        border: solid 1px rgb(187, 187, 187);
        border-radius: 5px;
        margin-bottom: 10px;

        .comentario, .fecha {
            padding: 5px 10px;
        }

        .fecha {
            background-color: rgb(238, 238, 238);
            font-size: 0.8em;
            text-align: right;
        }
    }
</style>