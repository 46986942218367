<template>
	<div>
		<div v-if="get_options.bg" class="bpb-background" :style="get_zindex"></div>
		<div :class="get_class" :style="get_width+';'+get_height+';'+get_zindex">
			<slot/>
			<div v-if="get_options.close" class="close icon-cross" @click="close"></div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			options: {
				type: Object
				,default: () => ({
					style: 'modal'
					,width: '60vw'
					,height: 'auto'
					,bg: true
					,close: false
					,type: 'info'
					,esc: true
					,name: 'generic_modal'
				})
			}
		}
		,data: () => ({
			// idModal: 0
		})
		,mounted: function() {
			this.idModal = document.querySelectorAll('.bpb_modal').length

			if (typeof(this.options.esc) == 'undefined')
				this.options.esc = true;

			if (typeof(this.options.name) == 'undefined')
				this.options.name = 'generic_modal';

			if (this.options.esc) {
				let that = this;

				document.addEventListener('keyup', e => {
					// that.$log.info('es el modal: ',this.idModal);
					if (e.keyCode == 27)
						that.close();
				})
			}
		}
		,destroyed: function() {
			let that = this;

			document.removeEventListener('keyup', e => {
				// that.$log.info('Destroy');
			})
		}
		,computed: {
			get_options: function() {
				let options = this.options;

				if (!options.style)
					options.style = 'modal';

				if (options.style && options.style == 'modal' && !options.width)
					options.width = '60vw';

				if (options.style && options.style == 'modal' && !options.height)
					options.height = 'auto';

				if (!options.type)
					options.type = 'default';

				if (typeof(options.bg) == 'undefined')
					options.bg = true;

				if (!options.close)
					options.close = false;

				return options;
			}
			,get_class: function() {
				let clases = 'bounceInLeft ';

				clases = this.get_options.style == 'modal' ? 'bpb_modal' : 'bpb_toast'

				switch(this.options.type) {
					case 'info':
						clases += this.get_options.style == 'modal' ? ' bpb_modal_info' : ' bpb_toast_info';
						break;
					case 'secondary':
						clases += this.get_options.style == 'modal' ? ' bpb_modal_secondary' : ' bpb_toast_secondary';
						break;
					case 'success':
						clases += this.get_options.style == 'modal' ? ' bpb_modal_success' : ' bpb_toast_success';
						break;
					case 'warning':
						clases += this.get_options.style == 'modal' ? ' bpb_modal_warning' : ' bpb_toast_warning';
						break;
					case 'error':
					case 'danger':
						clases += this.get_options.style == 'modal' ? ' bpb_modal_error' : ' bpb_toast_error';
						break;
					default :
						clases += this.get_options.style == 'modal' ? ' bpb_modal_info' : ' bpb_toast_info';
				}

				if (this.options.name)
					clases += ' '+this.options.name;

				return clases;
			}
			,get_width: function() {
				return this.get_options.style == 'modal' ? 'width:'+this.get_options.width : null;
			}
			,get_height: function() {
				return this.get_options.style == 'modal' ? 'height:'+this.get_options.height : null;
			}
			,get_zindex: function(bg) {
				let inicio = 1200;
				let modales = document.querySelectorAll('div.bpb_modal,div.bpb_toast');
				return 'z-index: '+(modales.length > 0 ? inicio+modales.length : inicio);
			}
		}
		,methods: {
			close: function() {
				if (this.idModal == document.querySelectorAll('.bpb_modal').length)
					this.$emit('close');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.bpb-background {
		width: 100%;
		height: 100%;
		background-color: #4C4C4C;
		opacity: 0.5;
		position: fixed;
		top: 0;
		left: 0;
	}

	.bpb_modal {
		background-color: #fff;
		border-radius: 4px;
		-webkit-box-shadow: 5px 5px 10px 0px rgba(66,65,66,1);
		-moz-box-shadow: 5px 5px 10px 0px rgba(66,65,66,1);
		box-shadow: 5px 5px 10px 0px rgba(66,65,66,1);
		transform: translate(-50%, -50%);
		// overflow: hidden;
		position: fixed;
		top: 50%;
		left: 50%;

		.title, .body, .footer {
			padding: 10px 15px;
		}

		.title {
			color: #fff;
			font-size: 1.4em;
		}

		.body {
			max-height: 75vh;
			overflow: auto;
		}

		.close {
			font-size: 0.9em;
			position: absolute;
			top: 15px;
			right: 13px;
		}
	}

	.bpb_modal_info {
		border: solid 1px #0167A7;
		.title {
			background-color: #0781cd;
		}
	}

	.bpb_modal_secondary {
		border: solid 1px #666666;
		.title {
			background-color: #666666;
		}
	}

	.bpb_modal_success {
		border: solid 1px #20B42E;
		.title {
			background-color: #20B42E;
		}
	}

	.bpb_modal_warning {
		border: solid 1px #E3B725;
		.title {
			background-color: #E3B725;
			color: #000;
		}
	}

	.bpb_modal_error {
		border: solid 1px #CA3939;
		.title {
			background-color: #CA3939;
		}
	}

	.bpb_toast {
		min-width: 400px;
		background-color: #fff;
		border-radius: 5px;
		display: flex;
		flex-direction: row;
		overflow: auto;
		position: fixed;
		top: 10px;
		right: 10px;

		.body {
			flex: 1 1 auto;
		}

		.footer {
			flex-shrink: 0;
		}
	}

	.bpb_toast_info {
		.title {
			background-color: #0781cd;
			color: #fff;
			padding: 10px 15px;
		}

		.body {
			background-color: #E6E6E6;
		}
	}

	.bpb_toast_secondary {
		.title {
			background-color: #666666;
			color: #fff;
			padding: 10px 15px;
		}

		.body {
			background-color: #999999;
		}
	}

	.bpb_toast_success {
		.title {
			background-color: #189C13;
			color: #fff;
			padding: 10px 15px;
		}

		.body {
			background-color: #E6E6E6;
		}
	}

	.bpb_toast_error {
		.title {
			background-color: #C91E1E;
			color: #fff;
			padding: 10px 15px;
		}

		.body {
			background-color: #E6E6E6;
		}
	}

	.icon-cross:before {
		content: "\ea0f";
	}

	@media (max-width: 768px) {
		.bpb_modal {
			width: 90vw !important;
			max-height: 90vh !important;
			overflow: auto !important;
		}
	}

	@media (max-width: 667px) {
		.bpb_modal {
			width: 95vw !important;
			max-height: 95vh !important;
			transform: translate(-50%,-50%);
			overflow: auto !important;
		}
	}
</style>